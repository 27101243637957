export default {
  proxy_path: "https://3sk1phd4pa.execute-api.us-west-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://dgupnm48s9.execute-api.us-west-2.amazonaws.com/api",
  google_api_key: "AIzaSyCvHe11XoXXIdcqwKIRI9kivWPjAvNdaAQ",
  main_company_term_id: "6",
  crm_base_url: "https://crm.staging.newmont.forwoodsafety.com",
  forwood_id_url: "https://id.staging.newmont.forwoodsafety.com?redirect_uri=https://mapreport.staging.newmont.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.staging.newmont.forwoodsafety.com",
  Auth: {
    userPoolId: "us-west-2_LLKeAlZj0",
    userPoolWebClientId: "2vil0n90ato9a423msh1hoga3v",
    cookieStorage: {
      domain: ".staging.newmont.forwoodsafety.com",
      secure: true
    }
  }
};
